import React from "react";
// import aboutImage1 from "../../images/about-img.jpg";
import aboutImage2 from "../../images/jci-india.png";
import sectionIcon from "../../images/section-icon.png";
const WhyJci = () => {
  return (
    <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="about-semi-img">
              <img src={aboutImage2} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-heading">
              <div className="section-heading">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">JCI India Foundation</h2>
                {/* <p className="section__meta">why JCI</p> */}
                <p className="section__desc text-justify">
                  The JCI India Foundation earlier called as IJC Charitable Trust, is a trust created to provide financial support for JCI India programs and activities. The funds donated to the Foundation are invested in training, financial help to school children who are economically deprived. To find the talented children and help them pursue their studies is one of the programs of JCI India.
                </p>
                <p className="section__desc text-justify">
                  Through the Foundation, JCI India has been able to conduct programs that have assisted community leaders around the country and have helped to develop tomorrow’s leaders. Donations to the Foundation remain in an interest-bearing account, and the interest generated on such gifts is allotted for JCI India programs. Any member, former member, JCI Senator, chapter, national organization, friend, philanthropist or corporation is invited to donate to the JCI Foundation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJci;
